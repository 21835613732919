import React from 'react';
import terminal from '../assets/tax.png'

const About = () => {
  return (
    <div className='w-full bg-[#ffc233] text-black text-center'>
      <div className='max-w-[1240px] mx-auto px-4 py-16 '>
        <div id="roadmap">
        <h1>MAMU THE FROG KING</h1> <br /><h1>CA:</h1><br />
        <p className='text-3xl'>Mamu, the one and only Frog King, is a legend across the Frogniverse—a mystical figure who first appeared as the end boss in Yume Kōjō: Doki Doki Panic, later reimagined as Wart in Super Mario Bros. 2 in 1988.</p>
        <p className='text-3xl'>CA: 0xf946Fc3C4EA7AA3D121ff7E4042E19cC03027fD3</p>


        <img className='justify-center' src={terminal} alt="/" />
        <a href="https://app.uniswap.org/#/swap?outputCurrency=" target="_blank" rel="noopener noreferrer"> <button type="button" class="text-black hover:text-white border border-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-black font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-black dark:text-black dark:hover:text-white dark:hover:bg-black dark:focus:ring-teal-900">BUY $MAMU</button></a>
        <a href="https://www.dextools.io/app/en/ether/pair-explorer/" target="_blank" rel="noopener noreferrer"> <button type="button" class="text-black hover:text-white border border-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-black font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-black dark:text-black dark:hover:text-white dark:hover:bg-black dark:focus:ring-teal-900">DEXTOOLS</button></a>
          <div>
<iframe className='py-4 w-full aspect-video' src="https://dexscreener.com/ethereum/" title="dexchart"></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
